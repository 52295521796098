//Modules
import { useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'

//Utils
import useFetch from '../utils/useFetch'
import { menuContext } from '../utils/UtilContext'

const { REACT_APP_CACHE_EP_MENU, REACT_APP_WP_MENU_ICONS } = process.env

const IconBar = () => {
    const { CACHE_API_URL } = useContext(menuContext)
    const { data: navIcons } = useFetch(`${CACHE_API_URL}${REACT_APP_CACHE_EP_MENU}${REACT_APP_WP_MENU_ICONS}`)

    let history = useLocation()
    const lang = history.pathname.substring(1,3)

    if (!navIcons) {return (<></>)}

    const { items: icons } = navIcons

    return (
        <>
            <div className="bottomBar container">
                <ul className="icons">
                    {icons.map((icon) => (
                        <li key={icon.ID} className="icon">
                            {icon.url !== "" ?
                                icon.url.includes("http") ?
                                    <a href={icon.url} target="_blank" rel="noreferrer">
                                        <span className="material-icons-outlined">{icon.acf.icon_id}</span>
                                        <span className="textIcon">{icon['title_'+lang]}</span>
                                    </a>
                                    :
                                    <Link to={icon.url} >
                                        <span className="material-icons-outlined">{icon.acf.icon_id}</span>
                                        <span className="textIcon">{icon['title_'+lang]}</span>
                                    </Link>
                                :
                                <>
                                    <span className="material-icons-outlined">{icon.acf.icon_id}</span>
                                    <span className="textIcon">{icon['title_'+lang]}</span>
                                </>
                            }
                        </li>
                    ))}                    
                </ul>
            </div>  
        </>
    )
}

        export default IconBar
