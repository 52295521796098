//Modules
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Components
import Nav from '../widgets/Nav/Nav'

function NotFound() {
    const navigate = useNavigate()

    const [countdown, setCountdown] = useState(4)

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1)
        }, 1000)

        const timeout = setTimeout(() => {
            navigate("/")
        }, 4000)

        return () => {
            clearInterval(timer)
            clearTimeout(timeout)
        }
    }, [navigate])

    return(
        <>
        <div className="container-fluid sticky-top" style={{backgroundColor: "white", zIndex: "9999"}}>
            <Nav dest={false} content={false} subItem={false}/>
        </div>
        <div style={{textAlign: 'center', marginTop: '80px', marginBottom: '80px'}} className="container-fluid">
            <div className="not-found">
                <h1>404</h1>
                <p>A página que procura, não existe.</p>
                <p>Vai ser redirecionado para a página inicial em {countdown} segundos.</p>
            </div>
        </div>                                   
        </>
    )
}

export default NotFound
