// Modules
import { useContext, useRef } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Icon } from '@iconify/react'

//Utilities
import { createRoutes } from './components/utils/utilFunctions'
import { menuContext, NewsContextProvider, EventsContextProvider } from './components/utils/UtilContext'

//Components
import Home from './components/pages/Home'
import Page from './components/pages/Page'
import Noticias from './components/pages/Noticias'
import NoticiaDetalhe from './components/pages/NoticiaDetalhe'
import Eventos from './components/pages/Eventos'
import Footer from './components/widgets/Footer'
import EventoDetalhe from './components/pages/EventoDetalhe'
import NotFound from './components/pages/NotFound'
import SearchForm from './components/widgets/Search/SearchForm'
import NavMobile from './components/widgets/Nav/NavMobile'
import MiniNav from './components/widgets/Nav/MiniNav'
import ToTop from './components/widgets/ToTop'

function App() {
  const { loading, navItemsTotal } = useContext(menuContext)
  const inputRef = useRef(null)

  let menuRoutes_pt = []
  let menuRoutes_en = []
  let menuRoutes = []

  const handleFocus = () => {
    console.log(inputRef.current)
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  if (loading) {
    return <Icon icon="svg-spinners:ring-resize" />        
  }else{
    menuRoutes_pt = createRoutes(navItemsTotal, 'pt')
    menuRoutes_en = createRoutes(navItemsTotal, 'en')
    menuRoutes = createRoutes(navItemsTotal, '')
     
    return (
      <div>        
        <SearchForm inputRef={inputRef} />
        <div className='pageOverlay'></div>
        <NavMobile />
        <MiniNav menuPT={menuRoutes_pt} menuEN={menuRoutes_en} handleFocus={handleFocus} />
        {navItemsTotal &&
          <Routes>
            {/* <Route path="/" element={<Navigate to={'/'+navigator.language.substring(0,2)} />} /> */}
            <Route path="/" element={<Navigate to={'/pt'} />} />
            <Route path="/pt" element={<Home />} />
            <Route path="/en" element={<Home />} />
            <Route path='/pt/noticias'>             
              <Route index element={<NewsContextProvider><Noticias /></NewsContextProvider>} />                       
              <Route path=":slugNews" element={<NoticiaDetalhe />} />
            </Route>
            <Route path='/en/news'>             
              <Route index element={<NewsContextProvider><Noticias /></NewsContextProvider>} />                       
              <Route path=":slugNews" element={<NoticiaDetalhe />} />
            </Route>
            <Route path='/pt/eventos'>
              <Route index element={<EventsContextProvider><Eventos /></EventsContextProvider>} />
              <Route path=":slugEvento" element={<EventoDetalhe />} />
            </Route>
            <Route path='/en/events'>
              <Route index element={<EventsContextProvider><Eventos /></EventsContextProvider>} />
              <Route path=":slugEvento" element={<EventoDetalhe />} />
            </Route>        
            {navItemsTotal.redirect.items.map((redirect, index) => (
              <Route key={index} path={redirect.url} element={<Navigate to={redirect.title} />} />
            ))}
            {menuRoutes_pt.map((menu, index) => (
              <Route key={index} path={menu.urlRoute}>
                <Route index element={<Page menu={menu} noLang={false} />} />
                {menu.urlRoute.includes("/estudar/cursos") && 
                  <Route path=':slugCurso' element={<Page menu={menu} noLang={false} />} />}              
              </Route>
            ))}            
            {menuRoutes_en.map((menu, index) => (
              <Route key={index} path={menu.urlRoute}>
                <Route index element={<Page menu={menu} noLang={false} />} />
                {menu.urlRoute.includes("/study/courses") && 
                  <Route path=':slugCurso' element={<Page menu={menu} noLang={false} />} />}              
              </Route>
            ))}
            {menuRoutes.map((menu, index) => (
              <Route key={index} path={menu.urlRoute}>
                <Route index element={<Navigate to={`/pt${menu.urlRoute}`} />} />
                {menu.urlRoute.includes("/estudar/cursos") && 
                  <Route path=':slugCurso' element={<Page menu={menu} noLang={true} />} />}              
              </Route>
            ))}
            
            <Route path="*" element={<NotFound />} />    
        </Routes>}
        <ToTop />  
        <Footer />
      </div>
    )
  }
}

export default App;