import React, { Fragment } from 'react'

function MaisInfo({ detalhe }) {

  return (
    <article className='py-5'>
        <div className="container page">
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-9">
                    <div className="position-relative">


                        <div className="accordion maisInfo">
                            <div className="card cardAccordion">
                                <div className="card-header" id="headingOne">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <strong>Diploma Conferido</strong>
                                        <span></span>                                   
                                    </button>
                                </div>

                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" >
                                    <div className="card-body">                                            
                                            {detalhe.planos[0].plano.map((data, index) => (
                                                <p key={index}>
                                                    {data?.qualification_awarded ? 
                                                        <>
                                                            {data.$.ramo !== "" && <><strong>Ramo:</strong> {data.$.ramo}<br /></>}
                                                            {data.qualification_awarded[0]}
                                                        </>
                                                    : "Não se aplica." 
                                                    }<br />
                                                </p> 
                                            ))}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingTwo">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <strong>Nível da Qualificação</strong>
                                        <span></span>
                                    </button>                                                         
                                </div>

                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" >
                                    <div className="card-body">
                                        {detalhe?.nivel_qualificacao ? detalhe.nivel_qualificacao.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingThree">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <strong>Requisitos de Acesso</strong>
                                        <span></span>
                                    </button>                                                                 
                                </div>

                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" >
                                    <div className="card-body">
                                        {detalhe?.admission_requirements ? detalhe.admission_requirements.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingFour">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <strong>Creditação das aprendizagens prévias</strong>
                                        <span></span>
                                    </button>                                                                     
                                </div>

                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" >
                                    <div className="card-body">
                                        {detalhe?.aprendizagens_previas ? detalhe.aprendizagens_previas.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingFive">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        <strong>Requisitos da qualificação e regulamentos</strong>
                                        <span></span>
                                    </button>                                 
                                </div>

                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" >
                                    <div className="card-body">                                    
                                        {detalhe.planos[0].plano.map((data, index) => (
                                                <p key={index}>
                                                    {data?.duracao ? 
                                                        <>
                                                            {data.$.ramo !== "" && <><strong>Ramo:</strong> {data.$.ramo}<br /></>}
                                                            {data.duracao[0]}
                                                        </>
                                                    : "Não se aplica." 
                                                    }<br />
                                                </p> 
                                            ))}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingSix">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        <strong>Perfil do Programa de estudos</strong>
                                        <span></span>
                                    </button>                                   
                                </div>

                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" >
                                    <div className="card-body">
                                        

                                        {detalhe.planos[0].plano.map((data, index) => (
                                            <p key={index}>
                                                {data?.perfil_prog_estudos ? 
                                                    <>
                                                        {data.$.ramo !== "" && <><strong>Ramo:</strong> {data.$.ramo}<br /></>}
                                                        {"O perfil do programa de estudos compreende o desenvolvimento do conhecimento técnico-científico e do saber de natureza profissional nas áreas de estudos de " + data.perfil_prog_estudos[0]}
                                                    </>
                                                : "Não se aplica." 
                                                }<br />
                                            </p> 
                                        ))}

                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingSeven">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        <strong>Principais resultados da aprendizagem</strong>
                                        <span></span>
                                    </button>                                 
                                </div>

                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" >
                                    <div className="card-body">
                                        
                                        {detalhe.planos[0].plano.map((data, index) => (
                                                <p key={index}>
                                                    {data?.requisitos ? 
                                                        <>
                                                            {data.$.ramo !== "" && <><strong>Ramo:</strong> {data.$.ramo}<br /></>}
                                                            {data.requisitos[0]}
                                                        </>
                                                    : "Não se aplica." 
                                                    }<br />
                                                </p> 
                                            ))}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingEight">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                        <strong>Perfil ocupacional dos diplomados</strong>
                                        <span></span>
                                    </button>                                  
                                </div>

                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight" >
                                    <div className="card-body">
                                    {detalhe?.perfil_diplomados ? detalhe.perfil_diplomados : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingNine">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                        <strong>Acesso a outros ciclos de estudos</strong>
                                        <span></span>
                                    </button>                                  
                                </div>

                                <div id="collapseNine" className="collapse" aria-labelledby="headingNine" >
                                    <div className="card-body">
                                        {detalhe?.access_further_studies ? detalhe.access_further_studies.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingTen">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        <strong>Requisitos de graduação</strong>
                                        <span></span>
                                    </button>                                   
                                </div>

                                <div id="collapseTen" className="collapse" aria-labelledby="headingTen" >
                                    <div className="card-body">
                                        {detalhe?.final_examination ? detalhe.final_examination.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingEleven">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                        <strong>Regulamentos de exames, avaliação e classificação</strong>
                                        <span></span>
                                    </button>                                   
                                </div>

                                <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" >
                                    <div className="card-body">
                                        {detalhe.regulations ? detalhe.regulations : "Não se aplica."} 
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingTwelve">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                        <strong>Regime de estudos</strong>
                                        <span></span>
                                    </button>                                 
                                </div>

                                <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" >
                                    <div className="card-body">
                                        {detalhe.regime_estudos ? detalhe.regime_estudos : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingThirteen">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                        <strong>Acreditação/Registo</strong>
                                        <span></span>
                                    </button>                                 
                                </div>

                                <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" >
                                    <div className="card-body">
                                        {detalhe?.acreditacao_registo ? detalhe.acreditacao_registo.map((data, index) => (
                                            <Fragment key={index}>{index!==0 && <br/>}{data}</Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>

                            <div className="card cardAccordion">
                                <div className="card-header" id="headingFourteen">
                                    <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                        <strong>Documentos adicionais</strong>
                                        <span></span>
                                    </button>                                   
                                </div>

                                <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" >
                                    <div className="card-body">
                                        {detalhe.documentos[0]?.documento ? detalhe.documentos[0].documento.map((data, index) => (
                                            <Fragment key={index}>
                                                {index!==0 && <br/>}
                                                <a href={data.$.url} target='_blank' rel="noreferrer">{data.$.descricao}</a>
                                            </Fragment> 
                                        ))
                                        : "Não se aplica."}
                                    </div>
                                </div>
                            </div>
                            
                        </div>


                    </div>                                        
                </div>
            </div>
        </div>
    </article>
  )
}

export default MaisInfo